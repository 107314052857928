import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function debounce(fn, wait) {
  let timer = null;
  return function(...params) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...params);
    }, wait);
  }
}

export const pathMap = new Map();

const Index = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathMap.has(pathname)) {
      window.scrollTo(0, pathMap.get(pathname));
    } else {
      pathMap.set(pathname, 0);
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const fn = debounce(() => {
      pathMap.set(pathname, window.scrollY);
    }, 200);

    window.addEventListener('scroll', fn);
    return () => window.removeEventListener('scroll', fn);
  }, [pathname]);

  return null;
};

export default Index;
