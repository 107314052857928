import React from 'react'
import { useLocation } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import NavBar from '../../components/NavBar'

export default function Scores() {
  const { state } = useLocation();
  return (
    <>
      <NavBar title={state.title} link="/book/scores" />
        <TransformWrapper
          initialScale={1}
          limitToBounds={true}
          centerOnInit={true}
          centerZoomedOut={true}
          minScale={0.05}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent wrapperStyle={{height: window.innerHeight}}>
                <img src={state.link} alt="score" width="100%" />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
    </>
  )
}
