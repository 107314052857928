import React from 'react'
import { useLocation } from "react-router-dom";

import NavBar from '../../components/NavBar'
import PlyrVideo from '../../components/PlyrVideo';

export default function Covers() {
  const {state} = useLocation();
  return (
    <>
      <NavBar title={state.title} link="/book/covers" />
      {/* <video controls width="100%" >
        <source src={state.link} type="video/mp4" />
      </video> */}

      <PlyrVideo link={state.link} />
    </>
  )
}
