import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ScrollManager from "./scrollManager";
import reportWebVitals from "./reportWebVitals";
import Book from "./routes/Book";
import Covers from "./routes/preview/Covers";
import Scores from "./routes/preview/Scores";
import Looping from "./routes/preview/Looping";
import Stars from "./routes/preview/Stars";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="book/:bookId" element={<Book />} />
        <Route path="book/covers/preview" element={<Covers />} />
        <Route path="book/scores/preview" element={<Scores />} />
        <Route path="book/looping/preview" element={<Looping />} />
        <Route path="book/stars/preview" element={<Stars />} />
      </Routes>
    <ScrollManager />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
