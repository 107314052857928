import React from 'react'
import { useLocation } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import NavBar from '../../components/NavBar'
import PlyrVideo from '../../components/PlyrVideo';

export default function Stars() {
  const {state} = useLocation();
  // Check file type of state.link, by its file extension
  // If it is a .mp4, then render the video viewer
  // If it is an image file, then render the image viewer

  const fileType = state.link.split('.').pop();

  return (
    <>
      <NavBar title={state.title} link="/book/stars" />
      {fileType === 'mp4' ? (
        <PlyrVideo link={state.link} />
      ) : (
        <TransformWrapper
          initialScale={1}
          limitToBounds={true}
          centerOnInit={true}
          centerZoomedOut={true}
          minScale={0.05}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent wrapperStyle={{height: window.innerHeight}}>
                <img src={state.link} alt="score" width="100%" />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </>
  )
}
