import React, { forwardRef, useState, useEffect } from "react";
import Plyr from "plyr";
import { Button, TextField } from "@mui/material";

// import Plyr from "plyr-react";
// import "plyr-react/plyr.css";

const DummyPlyr = forwardRef((props, ref) => (
  <div>
    {/* this <div/> will not go away */}
    <Plyr ref={ref} {...props} />
  </div>
));
DummyPlyr.displayName = "DummyPlyr";

export default function PlyrVideo({ link }) {
  const [playerObject, setPlayerObject] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [loop, setLoop] = useState(false);
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const player = new Plyr("#player", {
      controls: [
        "play",
        "progress",
        "current-time",
        "duration",
        "mute",
        "volume",
        "settings",
      ],
      autoplay: true,
      speed: { selected: 1, options: [0.25, 0.4, 0.5, 0.75, 1, 1.25, 1.5] },
    });
    player.on("timeupdate", (event) => {
      setCurrentTime(event.detail.plyr.currentTime);
    });
    // setMaxTime to the duration of the video
    player.on("loadeddata", (event) => {
      setMaxTime(event.detail.plyr.duration);
      setDuration(event.detail.plyr.duration);
    });
    setPlayerObject(player);

    return () => {
      player.destroy();
    };
  }, []);

  useEffect(() => {
    // If loop is true and current
    if (loop) {
      if (currentTime >= maxTime) {
        playerObject.currentTime = parseFloat(minTime);
      }
    }
  }, [currentTime, loop, maxTime, minTime, playerObject]);

  useEffect(() => {
    // Set time to minTime
    if (playerObject) {
      playerObject.currentTime = parseFloat(minTime);
    }
  }, [minTime, playerObject]);

  return (
    <div>
      <video id="player" playsInline controls>
        <source src={link} type="video/mp4" />
      </video>
      <br />
      {/* c */}
      <Button variant="contained" onClick={() => setLoop(!loop)}>
        Loop
      </Button>

      {loop && (
        <center>
          <br />
          <h1>Looper i gang</h1>
          <br />
          <TextField
            label="Fra sek."
            variant="filled"
            value={minTime}
            min={0}
            max={duration - 1}
            type="number"
            onChange={(e) => setMinTime(e.target.value)}
          />
          <br />
          <br />
          <TextField
            label="Til sek."
            variant="filled"
            value={maxTime}
            min={1}
            max={duration}
            type="number"
            onChange={(e) => setMaxTime(e.target.value)}
          />
        </center>
      )}
    </div>
  );
}
