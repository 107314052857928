import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { Collapse } from "@mui/material";

const Search = styled("div")(({ theme, expanded }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: expanded ? 0 : theme.spacing(1),
  width: expanded ? "100%" : "auto",
  transition: theme.transitions.create(["width", "margin-left"], {
    duration: theme.transitions.duration.shorter,
  }),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(2), // Adjusted for better spacing
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function NavBar({ title, link, searchFunction }) {
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      sessionStorage.setItem(`${location.pathname}_scroll`, window.scrollY);
    };
  }, [location]);

  useEffect(() => {
    if (searchQuery) {
      searchFunction(searchQuery);
    }
  }, [searchQuery, searchFunction]);

  const popHistory = () => {
    navigate(-1);
    const savedScrollPosition = sessionStorage.getItem(
      `${location.pathname}_scroll`
    );
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
  };

  // Create a debounced version of the search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      searchFunction(query);
    }, 300),
    [searchFunction]
  );

  useEffect(() => {
    // Check if searchFunction is defined
    if (!searchFunction) {
      return;
    }
    // Call the search function immediately when the query is empty
    if (searchQuery === "") {
      searchFunction("");
    } else {
      debouncedSearch(searchQuery);
    }
  }, [searchQuery, debouncedSearch, searchFunction]);

  const handleSearchClick = () => {
    setSearchExpanded(true);
  };

  const handleBlur = () => {
    setSearchExpanded(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="back"
          sx={{ mr: 2 }}
          onClick={popHistory}
        >
          <ArrowBackIcon />
        </IconButton>
        {!searchExpanded && (
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title ? title : "Bassline Blue App"}
          </Typography>
        )}
        {link !== "/" ? null : (
          <>
            <Collapse
              in={searchExpanded}
              orientation="horizontal"
              sx={{ flexGrow: 1 }}
            >
              <Search expanded={searchExpanded.toString()}>
                <StyledInputBase
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "search" }}
                  autoFocus
                  onBlur={handleBlur}
                />
              </Search>
            </Collapse>

            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="search"
              onClick={handleSearchClick}
              sx={{ mr: 1.2 }}
            >
              <SearchIcon />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
