import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Fab from "@mui/material/Fab";

import NavBar from "../components/NavBar";
import useLocalStorage from "../hooks/useLocalStorage";

export default function Book() {
  let navigate = useNavigate();
  let { bookId } = useParams();
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [stars, setStars] = useLocalStorage("stars", {});

  useEffect(() => {
    let url = "";
    if (bookId === "scores") {
      url = "https://basundervisning.dk/Scores/scores.json";
    } else if (bookId === "covers") {
      url = "https://soundlab.dk/Covers/covers.json";
    } else if (bookId === "looping") {
      url = "https://soundlab.dk/Looping/looping.json";
    } else if (bookId === "stars") {
      let tempItems = [];
      Object.keys(stars).forEach((key) => {
        tempItems.push({
          title: key,
          link: stars[key],
        });
      });
      setItems(tempItems);
      setFilteredItems(tempItems);
      return;
    }
    // Make sure url is not none
    if (url === "") {
      return;
    }

    // Get initial items from the server
    // fetch(url)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setItems(data);
    //   });
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setItems(data);
        setFilteredItems(data); // Set initial filtered items
      });
  }, [bookId, stars]);

  const searchFunction = useCallback(
    (query) => {
      if (!query) {
        setFilteredItems(items);
        return;
      }

      const lowercaseQuery = query.toLowerCase();
      const filtered = items.filter(
        (item) =>
          item.title.toLowerCase().includes(lowercaseQuery) ||
          (item.description &&
            item.description.toLowerCase().includes(lowercaseQuery))
      );
      setFilteredItems(filtered);
    },
    [items]
  );

  //   const searchFunction = (query) => {
  //     if (!query) {
  //       setFilteredItems(items);
  //       return;
  //     }

  //     const lowercaseQuery = query.toLowerCase();
  //     const filtered = items.filter(
  //       (item) =>
  //         item.title.toLowerCase().includes(lowercaseQuery) ||
  //         (item.description &&
  //           item.description.toLowerCase().includes(lowercaseQuery))
  //     );
  //     setFilteredItems(filtered);
  //   };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavBar link={`/`} searchFunction={searchFunction} />

      {/* If no items return, then the book is not found */}
      {bookId === "looping" && items.length === 0 && (
        <Typography align="center" padding={4}>
          Kunne ikke finde noget her
        </Typography>
      )}

      {/* A card with icon, title and button in a row */}
      {filteredItems.map((item, index) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // flexDirection: 'column',
            alignItems: "center",
            p: 3,
            bg: "#f5f5f5",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          }}
          key={index}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                // Check if item is already in stars
                if (stars[item.title]) {
                  // Remove item from stars

                  // Copy stars
                  let newStars = { ...stars };
                  // Remove item from stars
                  delete newStars[item.title];
                  // Set new stars
                  setStars(newStars);
                } else {
                  // Add item to stars
                  setStars({
                    // Keep current titles (stars)
                    ...stars,
                    // Add new title with link
                    [item.title]: item.link,
                  });
                }
              }}
            >
              {item.title in stars ? (
                <StarIcon sx={{ color: "gold" }} />
              ) : (
                <StarBorderIcon sx={{ color: "gold" }} />
              )}
            </IconButton>
            <Typography align="left">{item.title}</Typography>
          </Box>
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            // style={{

            //     position: 'absolute',
            //     right: '0',
            //     bottom: '0',
            // }}
            onClick={() =>
              navigate(`/book/${bookId}/preview`, {
                state: { title: item.title, link: item.link },
              })
            }
          >
            <ChevronRightIcon color="white" />
          </Fab>
          {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        sx={{ mr: 2 }}
                        onClick={() => navigate("/")}
                    >
                        <ChevronRightIcon color="action" />
                    </IconButton> */}
        </Box>
      ))}
    </Box>
  );
}
