import { useNavigate, } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import Box from '@mui/material/Box';

export default function Folder({ title, description, link }) {
    let navigate = useNavigate();
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<Fab
                    color="primary"
                    size="small"
                    onClick={() => navigate(link)}
                >
                    <ChevronRightIcon />
                </Fab>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{ fontWeight: 600 }}>{title}</Typography>
            </AccordionSummary>
            {/* <AccordionDetails>
                <Box sx={{
                    // display: 'flex',
                    // // flexDirection: 'column',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    // width: '100%',
                    // height: '100%',
                    // p: 3,
                    // bg: '#f5f5f5',
                    // borderRadius: '5px',
                    // boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'

                }}>
                    <Typography align="center">
                        {description}
                    </Typography>
                    <Box>
                        <Fab
                            color="primary"
                            size="small"
                            aria-label="add"
                            style={{
                                position: 'absolute',
                                right: '1rem',
                                bottom: '1rem',
                            }}
                            onClick={() => navigate(link)}
                        >
                            <ChevronRightIcon />
                        </Fab>
                    </Box>
                    <br />
                    <br />
                    <br />
                </Box>
            </AccordionDetails> */}
        </Accordion>
    )
}
