import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


import Folder from './components/Folder';

function App() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
            </IconButton> */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Bassline Blue App
            </Typography>
          </Toolbar>
        </AppBar>

        <Folder title="Covers" description="Denne mappe indeholder en masse forskellige numre 
        med god bas, i forskellige stilarter som 
        Jazz, blues, latin, fusion, funk, rock, reggae, pop mv. 
        Numrene er beregnet som backingtracks så du kan øve basgangene henover musikken. 
        Der følger ingen instruktion med til disse numre, 
        fordi mappen mest er tiltænkt de bassister som modtager 
        privatundervisning via basundervisning.dk
          
        
        Selv om du ikke er elev på basundervisning.dk er du naturligvis velkommen til at 
        bruge numrene som øvefiler, men som sagt følger der ingen instruktion med. 
        
        
        Ønsker du at modtage privatundervisning, er du meget velkommen til at kontakte mig 
        på henrik@basundervisning.dk eller på 28 56 23 69, for en gratis prøvetime. 
        Undervisningen foregår på Katrinebjergvej 95A, 1.sal - 8200 Aarhus N."
          link="/book/covers" />

        <Folder title="Scores" description='Noderne i denne mappe hører til 
        jazz/blues/latin/fusions numrene fra " Covers" 
        mappen, som også findes i menuen "Øvefiler". 
        Der følger ingen instruktion med til disse noder, 
        fordi de mest er tiltænkt bassister som modtager privat undervisning via 
        basundervisning.dk. 
        
        Ønsker du at modtage privatundervisning, er du meget velkommen til at 
        kontakte mig på mail henrik@basundervisning.dk eller på 
        mobil 28 56 23 69, for en gratis prøvetime. 
        Undervisningen foregår på Katrinebjergvej 95A, 1.sal, 8200 Aarhus N.'
        link="/book/scores" />

        <Folder title="Looping" description="Tekst"  link="/book/looping" />

        <Folder title="Stjernemarkeringer" description="Tekst"  link="/book/stars" />

      </Box>
    </>
  );
}

export default App;
